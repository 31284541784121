<script setup>
import { useLayoutStore } from '~/stores/layout.store';
import { useUserStore } from '~/stores/user.store';
const userStore = useUserStore();
const user = computed(() => userStore.userGetter());

const layoutStore = useLayoutStore();
const links = ref([])
links.value = await layoutStore.fetchHelpfulLinks('project');
</script>
<template>
	<div>
		<h4 class="text-white text-2xl mb-5">{{ $t('client.helpful_links') }}</h4>
		<ul>
			<li v-for="(link, i) in links" class="list-none"><NuxtLink v-if="!link.only_for_logged_in_users||user!=undefined" :to="localePath(`/${link.url}`)"
					class="text-zinc-300 text-base mb-3 transition-all duration-300 hover:text-zinc-100  hover:ps-2">{{link.name}}</NuxtLink></li>
		</ul>
	</div>
</template>
